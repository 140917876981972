// color schemes
$color-primary: #2882bd;
$color-secondary: #d5bc58;
$deep-blue: #376db2;
$color-table-head: #98d5f4;
$color-yellow: #ffc40d;
$color-red: #cf4040;
$color-white: #ffffff;

// breakpoints
$xl: 1200px;
$lg: 1024px;
$md: 768px;
$sm: 480px;
$xs: 320px;

// font sizes
$body-small: 1.2rem;
$body-medium: 1.6rem;
$body-large: 2rem;

$header-small: 2rem;
$header-medium: 2.4rem;
$header-large: 2.8rem;
$header-xlarge: 3.2rem;

$btn-xsmall: 0.75rem;
$btn-small: 1rem;
$btn-medium: 1.4rem;
$btn-large: 2rem;
$btn-xlarge: 3rem;
