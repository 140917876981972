@import "./variables";
@import "./typography";
@import "./mixins";

// common
body {
	background-color: $color-primary;
}
.btn {
	border: none;
	transition: filter 0.25s ease;
	border-radius: 1rem;
	font-size: $btn-small;
	&.btn-primary {
		background-color: $deep-blue;
	}
	&.btn-secondary {
		background-color: $color-secondary;
	}
	&.btn-yellow {
		background-color: $color-yellow;
		color: white;
	}
	&.btn-red {
		background-color: $color-red;
		color: white;
	}
	&.btn-white {
		background-color: $color-white;
	}
	&.btn-shadow {
		box-shadow: 8px 8px 12px #333;
		&:focus,
		&:active:focus {
			outline: none;
			box-shadow: 8px 8px 12px #333;
		}
	}
	&.btn-xsm {
		font-size: $btn-xsmall;
		padding: 0.2rem 0.25rem;
	}
	&.btn-sm {
		font-size: $btn-small;
	}
	&.btn-md {
		padding: 0.25rem 1.5rem;
		font-size: $btn-medium;
	}
	&.btn-lg {
		padding: 0.5rem 1.75rem;
		font-size: $btn-large;
	}
	&.btn-xl {
		padding: 0.5rem 1.75rem;
		font-size: $btn-xlarge;
		text-transform: uppercase;
	}
	&:hover {
		filter: brightness(1.1);
	}
	&:focus,
	&:active:focus {
		outline: none;
		box-shadow: none;
	}
}
@media screen and (max-width: $md) {
	.btn {
		&.btn-lg {
			padding: 0.5rem 1.75rem;
			font-size: 1.6rem;
		}
		&.btn-xl {
			font-size: 2rem;
		}
	}
}

// anchor
a {
	color: inherit;
	text-decoration: none;
	&:hover {
		color: inherit;
	}
}

.circle {
	border-radius: 50%;
}
.text-right {
	text-align: right;
}

// form
input.form-control {
	padding: 8px 12px;
	font-size: $body-small;
	border-radius: 12px;
	&:focus {
		outline: none;
	}
	&::placeholder {
		font-style: italic;
		color: #ccc;
	}
}
.input-group {
	border-radius: 12px;
	border: 1px solid transparent;
	&:focus-within {
		border: 1px solid #86b7fe;
	}
	.form-control {
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		background-clip: border-box;
		border: 1px solid transparent;
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
	.input-group-text {
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
		background-color: white;
		border: none;
		cursor: pointer;
		font-size: $body-small;
	}
}

// table
.table-responsive {
	border-radius: 12px;

	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $color-secondary;
		border-radius: 12px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		filter: brightness(1.1);
	}
}
table.table {
	border-radius: 1rem;
	margin-bottom: 0;
	thead {
		font-weight: bold;
		font-size: $body-medium;
		& th {
			background-color: $color-table-head;
			border-bottom-width: 0px;
			position: sticky;
			top: 0;
		}
	}
	tbody {
		font-size: $body-medium;
		padding: 0 0.75rem;
		& tr {
			width: 200px;
		}
		& td {
			border-bottom: 2px solid #a5b0bf;
		}
	}
	svg {
		cursor: pointer;
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}
}
.table > :not(:last-child) > :last-child > * {
	border-bottom: none;
}
.tr-hoverable {
	cursor: pointer;
	transition: all 0.4s ease;
}
.tr-hoverable:hover {
	td {
		background-color: #ccc;
	}
}
