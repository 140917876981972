h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
label {
	color: white;
	font-family: "Nanum Gothic", sans-serif;
}
